import React, { Component } from 'react';
import withStyles from 'themableWithStyles';

import text1 from './assets/text1.txt';
import text2 from './assets/text2.txt';
import s from './HackerCode.css';

const queue = [text1, text2];
const typerSpeed = 25;

@withStyles(s)
class HackerCode extends Component {
  state = {
    queueCurrent: 0,
    typerIndex: 0,
    hackerText: '',
    currentText: text1,
  };

  componentDidMount() {
    this.init();
  }

  type = () => {
    const { queueCurrent, typerIndex, hackerText, currentText } = this.state;
    if (typerIndex >= currentText.length) {
      // Done with this file.
      // Proceed to the next.
      if (queueCurrent === queue.length - 1) {
        this.setState({ queueCurrent: 0, typerIndex: 0 });
        return;
      }
      this.setState(prev => ({
        queueCurrent: prev.queueCurrent + 1,
        typerIndex: 0,
      }));
    }

    // Slice out our section of the code that we are about to type.
    const slice = queue[queueCurrent].substring(
      typerIndex,
      typerIndex + typerSpeed,
    );

    // Type out slice of code, make sure to escape it first though
    this.setState(prev => ({ hackerText: prev.hackerText + slice }));

    if (hackerText.length > 2000) {
      // console.warn('truncating content');
      // If the hacks content has gotten to long, remove some lines
      this.setState({ hackerText: hackerText.substr(250, hackerText.length) });
    }

    // Move on to the other characters
    this.setState(prev => ({ typerIndex: prev.typerIndex + typerSpeed }));
  };

  init = () => {
    setInterval(this.type, 40);
  };

  render() {
    const { hackerText } = this.state;
    return (
      <div className={s.root}>
        <pre>{hackerText}</pre>
      </div>
    );
  }
}

export default HackerCode;
