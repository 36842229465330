exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ":root{@custom-media --xs-min (min-width: 36rem);@custom-media --sm-min (min-width: 48rem);@custom-media --md-min (min-width: 62rem);@custom-media --lg-min (min-width: 75rem);@custom-media --xl-min (min-width: 86rem);@custom-media --xs-max (max-width: calc(36rem - 0.525));@custom-media --sm-max (max-width: calc(48rem - 0.525));@custom-media --md-max (max-width: calc(62rem - 0.525));@custom-media --lg-max (max-width: calc(75rem - 0.525))}._1u0lC{-webkit-box-orient:vertical;-webkit-flex-direction:column;flex-direction:column;height:100%;padding:2rem}._1u0lC,._3_Xny{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-direction:normal}._3_Xny{-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-orient:horizontal;-webkit-flex-direction:row;flex-direction:row;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}._3_Xny h1{margin:0}.UErIm{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-flex:1;-webkit-flex:1 1;flex:1 1;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row}@media (max-width:65rem){._3_Xny{-webkit-flex-wrap:wrap;flex-wrap:wrap}._3Bbf6{-webkit-box-flex:0;-webkit-flex:none;flex:none;margin-top:1rem;-webkit-box-ordinal-group:4;-webkit-order:3;order:3;width:100%}.UErIm{-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column}.UErIm table{margin:1.5rem 0}}", ""]);

// Exports
exports.locals = {
	"container": "_1u0lC",
	"topContainer": "_3_Xny",
	"bottomContainer": "UErIm",
	"controlPanel": "_3Bbf6"
};