import React from 'react';

import Visual from '../../components/templates/Visual/Visual';

async function action() {
  return {
    // chunks: ['visual'],
    component: <Visual />,
  };
}

export default action;
