import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'themableWithStyles';

import s from './RandomBlock.css';

@withStyles(s)
class RandomBlock extends Component {
  render() {
    const { x, y, children } = this.props;
    return (
      <div
        style={{
          left: `${x}px`,
          top: `${y}px`,
        }}
        className={s.root}
      >
        {children}
      </div>
    );
  }
}

RandomBlock.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default RandomBlock;
