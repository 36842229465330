import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'themableWithStyles';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import styles from './MachineState.css';

const mapStateToProps = ({ status }) => ({
  status,
});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ ...fragrancesActions, ...ingredientActions }, dispatch);

@withStyles(styles)
@connect(
  mapStateToProps,
  null,
)
class MachineState extends PureComponent {
  static propTypes = {
    status: PropTypes.shape({
      machineState: PropTypes.number,
      flowState: PropTypes.number,
      generalStatus: PropTypes.shape({
        name: PropTypes.string,
        state: PropTypes.string,
      }),
    }).isRequired,
  };

  render() {
    const { status } = this.props;
    const { generalStatus } = status;

    return (
      <div
        className={classNames(styles.root, {
          [styles.warning]: generalStatus.state === 'warning',
          [styles.busy]: generalStatus.state === 'busy',
          [styles.error]: generalStatus.state === 'error',
          [styles.init]: generalStatus.state === 'init',
          [styles.off]: generalStatus.state === 'off',
        })}
      >
        STATUS
        <span className={styles.divider} />
        {generalStatus.name}
      </div>
    );
  }
}

export default MachineState;
