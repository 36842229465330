import React, { Component } from 'react';
import withStyles from 'themableWithStyles';

import s from './Visual.css';

import RandomBlock from '../../elements/RandomBlock/RandomBlock';
import HackerCode from '../../elements/HackerCode/HackerCode';

@withStyles(s)
class Visual extends Component {
  state = {
    x: 100,
    y: 200,
  };

  componentDidMount() {
    setInterval(this.changeBox, 5000);
    this.changeBox();
  }

  changeBox = () => {
    const newX = Math.random() * this.$root.offsetWidth - 600;
    const newY = Math.random() * this.$root.offsetHeight - 200;
    this.setState({
      x: newX > 0 ? newX : 0,
      y: newY > 0 ? newY : 0,
    });
  };

  render() {
    const { x, y } = this.state;
    return (
      <div
        ref={el => {
          this.$root = el;
        }}
        className={s.root}
      >
        <RandomBlock x={x} y={y}>
          <HackerCode />
        </RandomBlock>
      </div>
    );
  }
}

export default Visual;
