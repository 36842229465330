import {
  INGREDIENTS_FETCH_ALL_FAIL,
  INGREDIENTS_FETCH_ALL_REQUEST,
  INGREDIENTS_FETCH_ALL_SUCCESS,
} from '../constants';

// eslint-disable-next-line import/prefer-default-export
export function fetchStoreAccords() {
  return async (dispatch, getState, { fetch }) => {
    dispatch({
      type: INGREDIENTS_FETCH_ALL_REQUEST,
    });

    const { runtime } = getState();

    try {
      const result = await fetch(
        `/api/store-accords?$limit=200&storeId=${
          runtime.storeId
        }&$sort[pumpIndex]=1`,
      );
      dispatch({
        type: INGREDIENTS_FETCH_ALL_SUCCESS,
        payload: result.data,
      });
    } catch (e) {
      dispatch({
        type: INGREDIENTS_FETCH_ALL_FAIL,
        payload: e.message,
      });
    }
  };
}
