import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'themableWithStyles';

import styles from './Layout.css';

@withStyles(styles)
class Layout extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    return <div className={styles.root}>{children}</div>;
  }
}

export default Layout;
