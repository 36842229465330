import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'themableWithStyles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import s from './Status.css';
import * as orderActions from '../../../actions/orders.action';

const mapStateToProps = ({ orders }) => ({ orders });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...orderActions }, dispatch);

@withStyles(s)
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class Status extends PureComponent {
  static propTypes = {
    fetchLatestOrder: PropTypes.func.isRequired,
    orders: PropTypes.shape().isRequired,
  };

  componentDidMount() {
    const { fetchLatestOrder } = this.props;
    this.interval = setInterval(fetchLatestOrder, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { orders } = this.props;

    const currentOrder = orders.current || {};

    const fragranceName =
      currentOrder && currentOrder.fragrance && currentOrder.fragrance.title;

    const orderName = currentOrder.title;

    return (
      <div className={s.root}>
        <ul className={s.properties}>
          <li>STATUS:</li>
          <li>CURRENT:</li>
        </ul>
        <ul className={s.values}>
          <li className={s.statusList}>
            <span
              className={
                currentOrder.state === 'unknown' ? s.active : undefined
              }
            >
              IDLE
            </span>
            <span
              className={
                currentOrder.state === 'waiting' ? s.active : undefined
              }
            >
              WAITING
            </span>
            <span
              className={
                currentOrder.state === 'inProduction' ? s.active : undefined
              }
            >
              PRODUCTION
            </span>
            <span
              className={currentOrder.state === 'ready' ? s.active : undefined}
            >
              READY
            </span>
          </li>
          <li>
            {currentOrder.id
              ? `#${currentOrder.id} | ${orderName || fragranceName}`
              : 'Waiting'}
          </li>
        </ul>
      </div>
    );
  }
}

export default Status;
