import React, { PureComponent } from 'react';
import withStyles from 'themableWithStyles';
import PropTypes from 'prop-types';

import styles from './Home.css';

import Status from '../../modules/Status/Status';
import Ingredients from '../../elements/Ingredients';
import MachineState from '../../elements/MachineState';
import HackerCode from '../../elements/HackerCode/HackerCode';
import ControlPanel from '../../views/ControlPanel';

@withStyles(styles)
class Home extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
  };

  static defaultProps = {
    title: '',
  };

  render() {
    const { title } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <MachineState />
          <ControlPanel className={styles.controlPanel} />
          <h1>{title}</h1>
        </div>
        <Status />
        <div className={styles.bottomContainer}>
          {/* <div className={styles.leftContainer}>
            <div className={styles.orderList}>
              <h3>Upcoming orders:</h3>
              <ul>
                <li>#9999999 - Extra long order name cause why not</li>
                <li>#9999999 - Extra long order name cause why not</li>
                <li>#9999999 - Extra long order name cause why not</li>
                <li>#9999999 - Extra long order name cause why not</li>
                <li>#9999999 - Extra long order name cause why not</li>
              </ul>
            </div>
            <div className={styles.couponCode}>
              <h3>Coupon code:</h3>
              <h2>luxfilmfest</h2>
            </div>
          </div> */}
          <Ingredients />
          <HackerCode />
        </div>
      </div>
    );
  }
}

export default Home;
