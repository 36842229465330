import {
  ORDER_FETCH_FAIL,
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
} from '../constants';

// eslint-disable-next-line import/prefer-default-export
export function fetchLatestOrder() {
  return async (dispatch, getState, { fetch }) => {
    dispatch({
      type: ORDER_FETCH_REQUEST,
    });

    const { runtime } = getState();

    try {
      const { data: items } = await fetch(
        `/api/orders?$limit=10&$sort[createdAt]=1&$sort[priority]=-1&storeId=${
          runtime.storeId
        }&$or[0][state]=waiting&$or[1][state]=ready&$or[2][state]=inProduction`,
      );

      const current = (items && items[0]) || null;
      const waiting = (items && items.slice(1)) || null;

      const currentFragrance = await fetch(
        `/api/fragrances/${current.fragranceId}`,
      );

      let waitingFragrance = null;

      if (waiting) {
        waitingFragrance = await fetch(
          `/api/fragrances/${waiting.fragranceId}`,
        );
      }

      dispatch({
        type: ORDER_FETCH_SUCCESS,
        payload: {
          current: { ...current, fragrance: currentFragrance },
          waiting: { ...waiting, fragrance: waitingFragrance },
        },
      });
    } catch (e) {
      dispatch({
        type: ORDER_FETCH_FAIL,
        payload: e.message,
      });
    }
  };
}
