import { $$_MACHINE_ACTION } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export function kurtAction(payload) {
  return dispatch =>
    dispatch({
      type: $$_MACHINE_ACTION,
      payload,
    });
}

// eslint-disable-next-line import/prefer-default-export
export function reprintLabel() {
  return dispatch =>
    dispatch({
      type: 'websocket-io.actions.printer.label-reprint',
    });
}

export function assistanceCompleted() {
  return dispatch =>
    dispatch({
      type: 'websocket-io.actions.resolve.assist-completed',
    });
}
