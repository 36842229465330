exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ":root{@custom-media --xs-min (min-width: 36rem);@custom-media --sm-min (min-width: 48rem);@custom-media --md-min (min-width: 62rem);@custom-media --lg-min (min-width: 75rem);@custom-media --xl-min (min-width: 86rem);@custom-media --xs-max (max-width: calc(36rem - 0.525));@custom-media --sm-max (max-width: calc(48rem - 0.525));@custom-media --md-max (max-width: calc(62rem - 0.525));@custom-media --lg-max (max-width: calc(75rem - 0.525))}._2xB01{-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:#52ff00;color:#000;cursor:pointer;display:-webkit-box;display:-webkit-flex;display:flex;font-size:30px;height:45px;line-height:45px;margin:0;padding:0 1rem;text-align:center;width:auto}.Tsdxd{background-color:#5856d6}._57rxV{background-color:#ffae00}._1GOqJ{background-color:#e22d44}._3oMgd{background-color:#a1c5fc}.cz9Mw{background-color:#5856d6}._1n7E4{background-color:#000;display:block;height:85%;margin:0 1rem;width:2px}", ""]);

// Exports
exports.locals = {
	"root": "_2xB01",
	"busy": "Tsdxd",
	"warning": "_57rxV",
	"error": "_1GOqJ",
	"init": "_3oMgd",
	"off": "cz9Mw",
	"divider": "_1n7E4"
};