import {
  FRAGRANCES_FETCH_ALL_FAIL,
  FRAGRANCES_FETCH_ALL_REQUEST,
  FRAGRANCES_FETCH_ALL_SUCCESS,
} from '../constants';

// eslint-disable-next-line import/prefer-default-export
export function fetchFragrances(current) {
  return async dispatch => {
    dispatch({
      type: FRAGRANCES_FETCH_ALL_REQUEST,
    });

    if (current && current.fragrance) {
      dispatch({
        type: FRAGRANCES_FETCH_ALL_SUCCESS,
        payload: current.fragrance,
      });
    } else {
      dispatch({
        type: FRAGRANCES_FETCH_ALL_FAIL,
        payload: 'No Fragrance',
      });
    }
  };
}
