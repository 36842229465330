import React from 'react';

import Layout from '../../components/globals/Layout';
import Home from '../../components/templates/Home';

import { setRuntimeVariable } from '../../actions/runtime.action';

async function action({ query, store }) {
  store.dispatch(
    setRuntimeVariable({
      name: 'storeId',
      value: query.storeId,
    }),
  );

  const title = 'ALGORITHMIC PERFUMERY';

  return {
    title,
    chunks: ['home'],
    component: (
      <Layout>
        <Home title={title} />
      </Layout>
    ),
  };
}

export default action;
