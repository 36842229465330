exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2t_MD{border:2px solid #52ff00;display:-webkit-box;display:-webkit-flex;display:flex;margin:1.5rem 0;padding:0 1rem}ul{list-style:none;padding:0}._1Q7xm{width:25%}._1Q7xm li:nth-child(2){font-size:25px;margin:5px 0}._30LIm{width:60%}._30LIm li:nth-child(2){font-size:25px;margin:5px 0}._1Fint{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}._1Fint span{color:#756d6a}._2-WQ1{color:#52ff00!important}", ""]);

// Exports
exports.locals = {
	"root": "_2t_MD",
	"properties": "_1Q7xm",
	"values": "_30LIm",
	"statusList": "_1Fint",
	"active": "_2-WQ1"
};