exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1NpgQ{-webkit-box-flex:1;-webkit-flex:1 1;flex:1 1;margin-right:3.5rem}._1NpgQ,._1uEwb{display:-webkit-box;display:-webkit-flex;display:flex}._1uEwb{-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;width:100%}._3okJ9{font-size:25px;text-align:right}.kePaV{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;width:calc(50% - 15px)}._1MMj1{text-align:right}._3oTgP{text-align:left}", ""]);

// Exports
exports.locals = {
	"root": "_1NpgQ",
	"tbody": "_1uEwb",
	"th": "_3okJ9",
	"tr": "kePaV",
	"td": "_1MMj1",
	"alignLeft": "_3oTgP"
};