/* eslint-disable jsx-a11y/click-events-have-key-event, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import withStyles from 'themableWithStyles';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import styles from './ControlPanel.css';

import * as kurtActions from '../../../actions/kurt.actions';

const mapStateToProps = ({ kurt, status }) => ({ kurt, status });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...kurtActions }, dispatch);

@withStyles(styles)
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class ControlPanel extends PureComponent {
  static propTypes = {
    kurt: PropTypes.shape({
      activeBottles: PropTypes.number,
      exitBottles: PropTypes.number,
      errors: PropTypes.arrayOf(PropTypes.number),
      printer: { remaining: PropTypes.number, total: PropTypes.number },
    }).isRequired,
    className: PropTypes.string,
    reprintLabel: PropTypes.func.isRequired,
    assistanceCompleted: PropTypes.func.isRequired,
    status: PropTypes.shape({
      assistanceRequested: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    const {
      className: classNameProp,
      reprintLabel,
      status,
      assistanceCompleted,
    } = this.props;
    const { assistanceRequested } = status;

    return (
      <div className={classNames(classNameProp, styles.root)}>
        <div className={styles.button} onClick={reprintLabel} role="button">
          RE-PRINT LABEL
        </div>
        <Dialog open={!!assistanceRequested}>
          <DialogTitle>Assistance Required</DialogTitle>
          <DialogContent dividers>
            <List dense>
              {Array.isArray(assistanceRequested) &&
                assistanceRequested.map(assistanceText => (
                  <ListItem>
                    <ListItemText primary={assistanceText} />
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={assistanceCompleted}
              color="primary"
              variant="contained"
            >
              I finished the steps
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ControlPanel;
