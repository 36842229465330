import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'themableWithStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cN from 'classnames';

import s from './Ingredients.css';
import * as fragrancesActions from '../../../actions/fragrances.action';
import * as ingredientActions from '../../../actions/ingredients.action';

const mapStateToProps = ({ fragrances, orders, ingredients }) => ({
  fragrances,
  orders,
  ingredients,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...fragrancesActions, ...ingredientActions }, dispatch);

@withStyles(s)
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class Ingredients extends PureComponent {
  static propTypes = {
    fragrances: PropTypes.shape({}).isRequired,
    ingredients: PropTypes.shape({
      // ingredients: PropTypes.arrayOf(
      //   PropTypes.shape({
      //     name: PropTypes.string,
      //     quantity: PropTypes.number,
      //   }).isRequired,
      // ),
    }).isRequired,
    fetchFragrances: PropTypes.func.isRequired,
    fetchStoreAccords: PropTypes.func.isRequired,
    orders: PropTypes.shape({ current: PropTypes.shape() }).isRequired,
  };

  componentDidMount() {
    const { fetchStoreAccords } = this.props;
    fetchStoreAccords();
  }

  componentDidUpdate(prevProps) {
    const {
      orders: { current },
      fetchFragrances,
    } = this.props;

    if (
      current &&
      (prevProps.orders.current === null ||
        (prevProps.orders &&
          prevProps.orders.current &&
          prevProps.orders.current.id !== current.id))
    ) {
      fetchFragrances(current);
    }
  }

  getIngredients() {
    const {
      ingredients: { ingredients },
      fragrances: { fragrance },
    } = this.props;

    if (!ingredients) return null;

    const accords = (fragrance && fragrance.accords) || [];

    return ingredients.map(ingredient => {
      const fragranceAccord = accords.find(a => a.id === ingredient.accordId);
      const quantity = (fragranceAccord && fragranceAccord.quantity) || 0;

      return (
        <tr className={s.tr} key={`${ingredient.id}${quantity}`}>
          <td className={cN(s.td, s.alignLeft)}>{ingredient.accord.name}</td>
          <td className={s.td}>{quantity}</td>
          {/* <td className={s.td}>{ingredient.ingredientCategory.title}</td> */}
        </tr>
      );
    });
  }

  render() {
    return (
      <table className={s.root}>
        <tbody className={s.tbody}>
          <tr className={s.tr}>
            <th className={cN(s.alignLeft, s.th)}>NAME</th>
            {/* <th className={s.th} /> */}
            <th className={s.th}>AMOUNT</th>
          </tr>
          <tr className={s.tr}>
            <th className={cN(s.alignLeft, s.th)}>NAME</th>
            {/* <th className={s.th} /> */}
            <th className={s.th}>AMOUNT</th>
          </tr>
          {this.getIngredients()}
        </tbody>
      </table>
    );
  }
}

export default Ingredients;
